import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { EntityHeatmapCellsFactory } from '../../../react/components/Heatmap/factory';
import { HeatmapCellData } from '../../../react/components/Heatmap/models';
import { ShareDocOptions } from '../../dialog/share-doc-dialog/interface';
import { wrapIntoObservable } from '../../hd-wizard/utils';
import {
  ManagerProfile,
  ManagerProfilesFactory,
  ManagerProfilesFactoryV2
} from '../../../react/components/FormikElements/HdFormikAdsAccountSelector/models';

import { GoogleAdsVersionEnum } from '../../nodes/models';
import { getConfigDataFromHistoricalOption } from '../../nodes/nodes-settings/historical-load-option';
import { canSkipNewTables } from '../../nodes/nodes-settings/utils';
import { SourceConfigHelperService } from '../../nodes/source-settings-form/source-config-helper.service';
import { ConnectorAuthTypeEnum } from '../../nodes/source-type/auth-type';
import { JobMode, JobModeEnum } from '../../nodes/source-type/job-mode';
import { SOURCE_TYPES } from '../../nodes/source-type/source-type';
import { getDerivedSourceTypeFromSourceConfig } from '../../nodes/source-type/utils';
import { PaginationData } from '../../pagination/models';

import { DraftPipelinePayload } from '../../pipeline/draft-source/models';
import { IntegrationLag, IntegrationLagFactory, PipelinePriorityEnum } from '../../pipeline/models/models';
import { StorageKey } from '../../pipeline/storage-key';
import { AppConfig } from '../app.config';
import {
  BACKGROUND_NETWORK_REQ_OPTIONS,
  BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT,
  BACKGROUND_NETWORK_REQ_UI_OPTIONS, DEFAULT_NETWORK_REQ_OPTIONS
} from '../constants';
import { EventsBarGraphFactory } from '../models/events-bar-graph-data-point';
import { HevoEntity } from '../models/hevo-entity';
import { HTTPRequestMethodEnum, NetworkRequestOptions } from '../models/request';
import { WebHookFailureRecordsFactory } from '../models/webhook-failure-record';
import { createHttpParams } from '../../../react/legacy-utils/request';
import { RecentEntitiesService } from './recent-entities.service';
import { RxRequestService } from './rx-request.service';
import { SettingsStorageService } from './settings-storage.service';
import { SSHService } from './ssh.service';
import { TeamSettingsService } from './team-settings.service';
import { TeamService } from './team.service';


@Injectable()
export class SourceService {
  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService,
    private _recentEntitiesService: RecentEntitiesService,
    private _teamService: TeamService,
    private _sshService: SSHService,
    private _sourceConfigHelperService: SourceConfigHelperService,
    private _settingsStorageService: SettingsStorageService,
    protected _teamSettingsService: TeamSettingsService
  ) {
  }

  draftSourcesURL = this._appConfig.getDraftSourcesURL();
  sourcesURL = this._appConfig.getSourcesURL();
  integrationsURL = this._appConfig.getIntegrationsURL();
  statsURL = this._appConfig.getStatsURL();
  spikeAlertURL = this._appConfig.getSpikeAlertURL();

  pipelineDeleteObservable = new Subject<any>();
  sourceStatusChangeObservable = new Subject<any>();
  sourceListChangeObservable = new Subject<number>();
  sourceEditObservable = new Subject<any>();
  lagDataSubject = new Subject<{ id: number, lag: IntegrationLag }>();
  sourceErrorObservable = new BehaviorSubject<any>({});

  getIntegrationURL(id: any): string {
    return `${ this.integrationsURL }/${ id }`;
  }

  getSourceTypes(showLoading = true) {
    const requestUrl = this.sourcesURL + '/get-types';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: showLoading
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getSourceList() {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.get(this.sourcesURL, options);
  }

  getSampleSettings() {
    const requestUrl = this.sourcesURL + '/samples';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  validateSourceName(sourceName: string) {
    const requestUrl = this.integrationsURL + '/pre-validation';

    const params: any = {
      source_name: sourceName
    };

    return this._rxRequestService.put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  testConnection(data: any, sourceTypeIdentifier: string) {
    const options: NetworkRequestOptions = {
      uiOptions: {...BACKGROUND_NETWORK_REQ_UI_OPTIONS, ...BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT},
      networkOptions: {
        params: createHttpParams({
          integration_id: data.id,
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return this.buildConnectionParams(data, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const requestUrl = `${ this.sourcesURL }/${ sourceTypeIdentifier }/test`;

        return this._rxRequestService.post(requestUrl, options, params);
      })
    );
  }

  testConnectionDiagnose(data: any, sourceTypeIdentifier: string) {
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          integration_id: data.id,
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return this.buildConnectionParams(data, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const requestUrl = `${ this.sourcesURL }/${ sourceTypeIdentifier }/test/job`;

        return this._rxRequestService.post(requestUrl, options, params);
      })
    );
  }

  testRestApi(data: any, initialRequest: boolean, testPagination: boolean, payloadData: any): Observable<any> {
    const requestUrl = this.integrationsURL + '/rest-api/test';

    const body = {};
    if (testPagination) {
      body['offset'] = payloadData.offset;
    }

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return this.buildConnectionParams(data, 'RESTAPI').pipe(
      switchMap((configuration: any) => {
        body['config'] = configuration;
        body['integration_id'] = payloadData.integrationId;
        if (initialRequest) {
          body['config']['is_initial_test_connection'] = true;
        }
        return this._rxRequestService.post(requestUrl, options, body);
      })
    );
  }


  getIntegration(id: any, showLoading = false) {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading
      }
    };

    return this._rxRequestService.get(this.getIntegrationURL(id), options);
  }

  getIntegrationBySeqId(seqId: any, showLoading = false) {
    const requestUrl = this.integrationsURL + `/by-seq-id/${ seqId }`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showErrorMsg: false,
        showLoading
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getIntegrationDisplayDetails(id: any) {
    const requestUrl: string = this.getIntegrationURL(id) + '/display-details';

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).pipe(
      map((res) => {
        res.data.config = this._sshService.filterSSHDisplayConfig(res.data.config);
        return res;
      })
    );
  }

  setSourceError(data?: any) {
    this.sourceErrorObservable.next(data);
  }

  getIntegrationStats(id: any, minutes: any, maxPoints: any) {
    const requestUrl = this.statsURL + `/integration-summary/${ id }`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          since_minutes: minutes,
          max_points: maxPoints
        })
      }
    };

    return this._rxRequestService.get(requestUrl, options).pipe(
      map(res => {
        return EventsBarGraphFactory(res.data.stats);
      })
    );
  }

  getPipelineHeatmap(pipelineId: number, startTime: number, timeWindow: number): Observable<{ points: HeatmapCellData[], statsTs: number}> {
    const requestUrl = this.statsURL + `/v2/integration-summary/${ pipelineId }`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          from_ts: startTime,
          time_window_minutes: timeWindow
        })
      }
    };

    return this._rxRequestService.get(requestUrl, options).pipe(
      map((res) => {
        return res.data.stats.filter((stat) => ['Ingestion', 'Load'].includes(stat.description)).map((stat) => {
          return EntityHeatmapCellsFactory(stat.points, timeWindow);
        });
      })
    );
  }

  getJsonParsingStrategy(sourceTypeIdentifier: string, destinationTypeIdentifier: string) {
    const requestUrl = this.integrationsURL + '/json-parsing-strategy';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
      },
      networkOptions: {
        params: createHttpParams({
          source_type: sourceTypeIdentifier,
          destination_type: destinationTypeIdentifier
        })
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  addIntegration(data: any, sourceTypeIdentifier: string) {
    return this.getSourceParams(data, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const options: NetworkRequestOptions = {
          uiOptions: {
            showErrorMsg: false,
            showLoading: false
          },
          networkOptions: {
            params: createHttpParams({
              draft_source_id: this.getDraftDataIdFromStorage()
            })
          }
        };

        params['source_type'] = getDerivedSourceTypeFromSourceConfig(sourceTypeIdentifier, params['config']);

        return this._rxRequestService.post(this.integrationsURL, options, params).pipe(
          tap((res) => {
            this._recentEntitiesService.setRecent(HevoEntity.PIPELINE.value, res.data);
          })
        );
      })
    );
  }

  validateIntegration(data: any, sourceTypeIdentifier: string, skipSourceTest?: boolean) {
    return this.getSourceParams(data, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const requestUrl = this.integrationsURL + '/validate-source-settings';

        const options: NetworkRequestOptions = {
          uiOptions: {
            showErrorMsg: false,
            showLoading: false,
            ...BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT
          },
          networkOptions: {
            params: createHttpParams({
              draft_source_id: this.getDraftDataIdFromStorage(),
              // passing param to api only if skipSourceTest is true
              skip_source_test: !skipSourceTest ? undefined : skipSourceTest
            })
          }
        };

        return this._rxRequestService.post(requestUrl, options, params);
      })
    );
  }

  editIntegration(data: any, sourceTypeIdentifier: string, skipSourceTest?: boolean, skipMergeConfig?: boolean) {
    return this.getSourceParams(data, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const requestUrl = this.integrationsURL + `/${ data.id }/config`;

        const options: NetworkRequestOptions = {
          uiOptions: {
            showErrorMsg: false,
            showLoading: false,
            ...BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT
          },
          networkOptions: {
            params: createHttpParams({
              // passing param to api only if skipSourceTest is true
              skip_source_test: !skipSourceTest ? undefined : skipSourceTest,
              skip_merge_config: !!skipMergeConfig
            }),
          }
        };

        return this._rxRequestService.put(requestUrl, options, params);
      })
    );
  }

  updateSourceConfig(pipeline: any, payload: any) {

    const requestUrl = this.integrationsURL + `/${ pipeline.id }/config`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showErrorMsg: true,
        showLoading: false,
        ...BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT
      },
      networkOptions: {}
    };

    const networkPayload = {
      name: pipeline.source_name,
      source_type: pipeline.source_type,
      config:
        {
          ...payload
        }
    };

    return this._rxRequestService.put(requestUrl, options, networkPayload);
  }

  pausePipeline(pipelineID: number, forcePause: boolean) {
    const requestUrl = this.getIntegrationURL(pipelineID) + '/pause';

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          force: forcePause
        })
      },
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return this._rxRequestService.put(requestUrl, options);
  }

  resumePipeline(pipelineID: number) {
    return this._rxRequestService.put(this.getIntegrationURL(pipelineID) + '/resume', {
      ...DEFAULT_NETWORK_REQ_OPTIONS,
      uiOptions: {
        ...DEFAULT_NETWORK_REQ_OPTIONS.uiOptions,
        showErrorMsg: false
      }
    });
  }

  deletePipeline(pipelineID: number) {
    return this._rxRequestService.delete(this.getIntegrationURL(pipelineID));
  }

  updatePipelinePriority(pipelineID: number, priority: PipelinePriorityEnum) {
    return this._rxRequestService.put(this.getIntegrationURL(pipelineID) + `/priorities/${ priority }`);
  }

  getWebHookFailures(pipelineID: number, paginationData: PaginationData) {
    const requestUrl = this.getIntegrationURL(pipelineID) + `/web-hook/failures`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: {
          page: paginationData.page,
          limit: paginationData.limit
        }
      },
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return this._rxRequestService.get(requestUrl, options).pipe(
      map(res => {
        res.data.failure_records = WebHookFailureRecordsFactory(res.data.failure_records);
        return res.data;
      })
    );
  }

  getSourceParams(data: any, sourceTypeIdentifier: string) {
    return this.buildConnectionParams(data, sourceTypeIdentifier).pipe(
      map((connectionParamsData: any) => {
        const params: any = {
          name: data.name,
          source_type: sourceTypeIdentifier,
          config: Object.assign(
            {},
            connectionParamsData,
            {
              destination_prefix: data.destinationPrefix,
              s3_dest_partition_rules: data.directoryStructure
            }
          )
        };

        if (data.syncFrequencyPolicy) {
          params.config.execution_policy = data.syncFrequencyPolicy
        }
        else if (data.pipelineFrequencyPolicy) {
          params.config.execution_policy = data.pipelineFrequencyPolicy
        }

        if (data.destination) {
          params.destination_id = data.destination.id;
        }

        if (data.objectsConfig) {
          params.object_selection_criteria = data.objectsConfig;
        }

        if (typeof data.jsonStringMatchingStrategy !== 'undefined') {
          params.config.json_parsing_strategy = data.jsonStringMatchingStrategy;
        }

        if (data.autoMappingStatus) {
          params.auto_mapping_status = data.autoMappingStatus;
        }

        return params;
      })
    );
  }

  buildConnectionParams(data: any, sourceTypeIdentifier: string) {
    const sourceTypeMetaData = SOURCE_TYPES[sourceTypeIdentifier];
    const connectionParams = wrapIntoObservable(
      this._sourceConfigHelperService.getConfigHelper(
        sourceTypeIdentifier
      ).buildConnectionParams(data)
    );

    return connectionParams.pipe(
      map((res: any) => {
        if (data.jobMode) {
          this.appendJobMode(res, data.jobMode);
        }

        if (sourceTypeMetaData.requireAuth) {
          res.oauth_token_id = data.oauthTokenId;
        }

        if (sourceTypeMetaData.requireServiceAuth) {
          res.account_type = data.authType;

          if (data.authType === ConnectorAuthTypeEnum.OAUTH) {
            res.oauth_token_id = data.oauthTokenId;
          } else if (data.authType === ConnectorAuthTypeEnum.SERVICE) {
            res.security_service_account_id = data.securityServiceAccountId;
          }
        }

        if (sourceTypeMetaData.showHistoricalLoadOptions && data.historicalSyncDuration) {
          res = {
            ...res,
            ...getConfigDataFromHistoricalOption(sourceTypeIdentifier, data)
          };
        }

        if (sourceTypeMetaData.variation) {
          res.provider = sourceTypeMetaData.variation;
        }

        if (canSkipNewTables(data.jobMode, sourceTypeIdentifier)) {
          res.include_new_tables = data.includeNewTables;
        }

        return res;
      })
    );
  }

  appendJobMode(config: any, jobMode: JobMode) {
    if (jobMode.value === JobModeEnum.COLLECTION.value) {
      config.has_oplog = false;
      config.has_change_streams = false;
      return;
    }
    if (jobMode.value === JobModeEnum.OPLOG.value) {
      config.has_oplog = true;
      config.has_change_streams = false;
      return;
    }
    if (jobMode.value === JobModeEnum.CHANGE_STREAMS.value) {
      config.has_oplog = false;
      config.has_change_streams = true;
      return;
    }

    config.type = jobMode.value;
  }


  computeObjectsAndConfig(sourceTypeIdentifier: string, sourceConfig: any, jobMode: JobMode): Observable<string> {
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return this.buildConnectionParams({ ...sourceConfig, jobMode }, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const requestUrl = this.sourcesURL + `/${ sourceTypeIdentifier }/objects-and-config`;


        return this._rxRequestService.post(requestUrl, options, params).pipe(
          map((res) => res.data.session_id)
        );
      })
    );
  }

  computeObjectsAndConfigForStaticSource(sourceTypeIdentifier: string): Observable<string> {
    const requestUrl = this.sourcesURL + `/${ sourceTypeIdentifier }/objects-and-config`;

    return this._rxRequestService.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, {}).pipe(
      map((res) => res.data.session_id)
    );
  }

  getObjectsAndConfig(sessionId: string, listParams: any) {
    const requestUrl = this.sourcesURL + `/objects-and-config/${ sessionId }`;

    return this._rxRequestService.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, listParams);
  }


  getJobConfigStaticData(sourceTypeIdentifier: string, sourceConfig: any, jobMode: JobMode) {
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return this.buildConnectionParams({ ...sourceConfig, jobMode }, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const requestUrl = this.sourcesURL + `/${ sourceTypeIdentifier }/objects-and-config/static-data`;

        return this._rxRequestService.post(requestUrl, options, params);
      })
    );
  }


  getJobConfigDropdownData(
    url: string,
    method: string,
    jobConfig: any,
    sourceConfig: any
  ) {
    const requestUrl = this._appConfig.getDynamicURL() + '/' + url;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false
      }
    };

    const reqBody = {
      source_config: sourceConfig,
      job_config: jobConfig
    };

    return this._rxRequestService.sendRequest(HTTPRequestMethodEnum[method], requestUrl, reqBody, options);
  }

  getGAViews(oauthTokenId: number) {
    const requestUrl = this.integrationsURL + `/google-analytics/views/${ oauthTokenId }`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return this._rxRequestService.get(requestUrl, options).pipe(
      map((res) => {
        return res.data?.items ? res.data.items : [];
      })
    );
  }

  onPipelineDelete(data: any) {
    this.pipelineDeleteObservable.next(data);
  }

  onSourceStatusChange(data?: any) {
    this.sourceStatusChangeObservable.next(data);
  }

  onSourceListChange(id: number) {
    this.sourceListChangeObservable.next(id);
  }

  onSourceEdit(data?: any) {
    this.sourceEditObservable.next(data);
  }


  updatePipelineAutoMappingStatus(
    pipelineID: number, autoMappingStatus: string
  ) {
    const requestUrl = this.getIntegrationURL(pipelineID) + `/auto-mapping`;
    const params: any = {
      status: autoMappingStatus
    };

    return this._rxRequestService.put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  getPipelineSetupProgress(pipelineID: number) {
    const requestUrl = this.getIntegrationURL(pipelineID) + `/progress`;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  getApiQuotaStatus(pipelineID) {
    const requestUrl = this.getIntegrationURL(pipelineID) + `/api-limits`;
    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  getLagData(pipelineID: number): Observable<IntegrationLag> {
    const requestUrl: string = this.statsURL + `/integrations/${ pipelineID }/lag`;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).pipe(
      map((res: any) => {
        const lag: IntegrationLag = IntegrationLagFactory(res.data);
        this.lagDataSubject.next({ id: pipelineID, lag });
        return lag;
      })
    );
  }

  getDateFormats() {
    const requestUrl: string = this.integrationsURL + '/rest-api/date-format';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showSuccessMsg: false
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getGoogleAdsPath(version: GoogleAdsVersionEnum) {
    return version === GoogleAdsVersionEnum.v1 ? 'google-adwords' : 'google-ads';
  }

  getAdwordsManagerProfiles(oAuthTokenId: number, version: GoogleAdsVersionEnum): Observable<ManagerProfile[]> {
    const requestUrl = this.integrationsURL + `/${ this.getGoogleAdsPath(version) }/oauth/${ oAuthTokenId }/customers`;
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return this._rxRequestService.get(requestUrl, options).pipe(
      map((res: any) => {
        return version === GoogleAdsVersionEnum.v1 ? ManagerProfilesFactory(res.data[oAuthTokenId]) : ManagerProfilesFactoryV2(res.data);
      })
    );
  }

  validateJobConfig(sourceConfig: any, sourceTypeIdentifier: string, queryData: any) {
    const requestUrl = this.integrationsURL + `/${ sourceTypeIdentifier }/validate/secondary-config`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return this.buildConnectionParams(sourceConfig, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const requestParams = {
          'source_config': { ...params },
          'data': { ...queryData }
        };

        return this._rxRequestService.post(requestUrl, options, requestParams);
      })
    );
  }

  getGCMProfilesAndReportTypes(oAuthTokenId: number) {
    const requestUrl = this.integrationsURL + `/cm/oauth/${ oAuthTokenId }/accounts/view`;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getSourceTypeMetadata(sourceTypeIdentifier: string) {
    const requestUrl = this.integrationsURL + '/display-meta';

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          source_type: sourceTypeIdentifier
        })
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getFBAAccounts(oauthTokenId: number) {
    const requestUrl = this.integrationsURL + `/fbads/accounts/${ oauthTokenId }`;
    // api being use just to verify if any valid accounts found or not.
    const params = { page: 1, limit: 1 };

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(params)
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getHistoricalLoadOptions(sourceTypeIdentifier: string) {
    const requestUrl = this.integrationsURL + `/${ sourceTypeIdentifier }/historical-load-options`;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).pipe(
      map((res: any) => res.data)
    );
  }

  shareSetupGuide(shareOptions: ShareDocOptions) {
    return this._teamService.shareSetupGuide(shareOptions);
  }

  getMongoDatabases(data: any, sourceTypeIdentifier: string) {
    const requestUrl = this.integrationsURL + `/${ sourceTypeIdentifier }/list-of-databases`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          integration_id: data.id,
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return this.buildConnectionParams(data, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        return this._rxRequestService.post(requestUrl, options, params);
      })
    );
  }

  createDraftSource(params: DraftPipelinePayload) {
    const requestUrl = this.draftSourcesURL;

    return this._rxRequestService.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params).pipe(
      map((res: any) => res.data)
    );
  }

  updateDraftSource(id: number, params: DraftPipelinePayload) {
    const requestUrl = `${ this.draftSourcesURL }/${ id }`;

    return this._rxRequestService.put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params).pipe(
      map((res: any) => res.data)
    );
  }

  deleteDraftSource(id: number, showLoading = false) {
    const requestUrl = `${ this.draftSourcesURL }/${ id }`;
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading
      }
    };

    return this._rxRequestService.delete(requestUrl, options);
  }

  getDraftSourceList() {
    const requestUrl = this.draftSourcesURL;
    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  getDraftSourceByID(id: string) {
    const requestUrl = `${ this.draftSourcesURL }/${ id }`;
    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).pipe(
      map((res: any) => {
        return {
          ...res.data,
          isDraft: true
        };
      })
    );
  }

  getDraftDataIdFromStorage() {
    if (!!this._teamSettingsService.getTeamSettingsValue()?.['draft_pipeline_enabled']) {
      const draftData = this._settingsStorageService.getSettings(StorageKey.CREATE_DRAFT_PIPELINE_SOURCE);
      return draftData && draftData.id ? draftData.id : null;
    }
    return null;
  }

  getIntegrationCreationInfo() {
    const requestUrl = `${this.integrationsURL}/creation_info`;
    return this._rxRequestService.get(requestUrl, { uiOptions: {
      showLoading: true,
      showSuccessMsg: false,
      showErrorMsg: false
    }});
  }

  getAmazonAdsProfiles(oAuthTokenId: number) {
    const requestUrl = `${this.integrationsURL}/amazon-ads/profiles/${oAuthTokenId}`;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).pipe(
      map((res: any) => res.data)
    );
  }

  getSpikeAlertStatus(integrationId: number) {
    const requestUrl = `${this.spikeAlertURL}/status/${integrationId}`;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).pipe(
      map((res: any) => res.data)
    );
  }
}
