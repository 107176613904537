import { Field, useFormikContext } from 'formik';
import React, { useRef, useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  getBackendErrorCodeFromObj,
  getErrorMessageFromObj
} from '../../../../../legacy-utils/request';
import HdSetupGuideLink from '../../../../../components/UIElements/HdSetupGuideLink';
import { getDataIdGenerator } from '../../../../../utils/generateDataId';
import { SnowflakeDestinationTrackingActions } from './tracking';
import { isPromiseCancelError } from '../../../../../legacy-utils/promise';
import { extractAccountAndRegionFromSnowflakeHostURL } from './utils';
import AdvancedConfig from '../../../../../components/AdvancedConfigWrapper';
import HdFormikDropDown from '../../../../../components/FormikElements/HdFormikDropDown';
import HdFormikPasswordField from '../../../../../components/FormikElements/HdFormikPasswordField';
import HdFormikSwitchWrapper from '../../../../../components/FormikElements/HdFormikSwitchWrapper';
import HdFormikTextField from '../../../../../components/FormikElements/HdFormikTextField';
import { LoadedAt } from '../../../../../components/NodeConfigOptions';
import HdDocLink from '../../../../../components/UIElements/HdDocLink';
import HdFormControl from '../../../../../components/UIElements/HdFormControl';
import HdLink from '../../../../../components/UIElements/HdLink';
import { trackSetupGuideLink } from '../../../../../utils/setupGuideLinkHandler';
import DestinationsAPI from '../../../DestinationsAPI';
import HdTag from '../../../../../components/UIElements/HdTag';
import { ConfigDestinationTypeBaseProps } from '../interface';
import { ConfigByType } from '../configByType';
import SnowflakeConstants, {
  SNOWFLAKE_WAREHOUSE_ERROR_CODES,
  SnowflakeAuthenticationType
} from './constants';
import styles from './styles.module.scss';
import FormApiError from '../../../../../components/FormApiError';
import useAnalyticsTracker from '../../../../../hooks/useAnalyticsTracker';
import { ConnectionSettingsRadioGroup } from './ConnectionSettingsRadioGroup';
import HdFormikFileField from '../../../../../components/FormikElements/HdFormikFileField';
import useTeamSettings from '../../../../../hooks/services/useTeamSettingsService';

interface SnowflakeResource {
  id: number;
  name: string;
}

const defaultUpdateState = {
  invalidUserName: null,
  invalidPassword: null,
  invalidAccountUrl: null,
  formError: null
};

function WarehouseDropdownOption({ option, dataIdGenerator }) {
  return (
    <div className='d-flex justify-between w-100'>
      <div className='body-text-3 text-medium'>{option.name}</div>
      {option.isDefaultWarehouse && (
        <HdTag dataId={dataIdGenerator('warehouse-default')} className={styles.defaultWarehouseTag}>
          DEFAULT
        </HdTag>
      )}
    </div>
  );
}

function DropdownRefreshAdornment({ handleRefreshResourceList, dataId }) {
  return (
    <HdLink
      tag='a'
      className={`w-100 px-4 py-3 text-primary `}
      onMouseDown={() => handleRefreshResourceList()}
      direction='right'
      icon='refresh'
      dataId={`${dataId}-dropdown-refresh`}
    >
      Refresh
    </HdLink>
  );
}

const prerequisiteFields = ['user', 'password', 'accountUrl'];

export interface SnowflakeDestinationFormikProps {
  authType: SnowflakeAuthenticationType;
  accountUrl: string;
  accountName: string;
  region: string;
  user: string;
  password: string;
  warehouse: SnowflakeResource;
  database: SnowflakeResource;
  schema: SnowflakeResource;
  warehouseName: string;
  databaseName: string;
  schemaName: string;
  formError?: { errorCode?: number; message?: string };
}

export default function SnowFlake({
  isEditing,
  maskSecretFields,
  formErrorCode,
  destinationTypeIdentifier,
  formMetaData,
  hevoEntityFor,
  destinationMode,
  experiments,
  isDestinationModeWarehouse
}: ConfigDestinationTypeBaseProps) {
  const isSnowflakeAutoFillExperimentVariant = experiments?.isSnowflakeAutoFillExperimentVariant;
  const { hostUrlSetupGuide, configureWarehouseSetupGuide } = SnowflakeConstants(
    isSnowflakeAutoFillExperimentVariant
  );

  const destinationTypeTrackingProps = {
    destinationType: destinationTypeIdentifier,
    context: hevoEntityFor
  };

  if (formErrorCode && SNOWFLAKE_WAREHOUSE_ERROR_CODES.includes(formErrorCode)) {
    trackSetupGuideLink({
      ...configureWarehouseSetupGuide('snowflake-warehouse-error'),
      medium: 'program',
      properties: destinationTypeTrackingProps
    });
  }

  const prevCredentials = useRef({
    username: '',
    password: ''
  });

  const [warehouses, setWarehouses] = useState(formMetaData?.warehouses || []);
  const [databases, setDatabases] = useState(formMetaData?.databases || []);
  const [schemas, setSchemas] = useState(formMetaData?.schemas || []);
  const [isUserSysAdmin, setUserSysAdminStatus] = useState(formMetaData?.isUserSysAdmin || false);

  const [loadingWarehouses, setLoadingWarehouses] = useState(false);
  const [loadingDatabases, setLoadingDatabases] = useState(false);
  const [loadingSchemas, setLoadingSchemas] = useState(false);

  const [showNoWarehouseError, setShowNoWarehouseError] = useState(false);
  const [showNoDatabaseError, setShowNoDatabaseError] = useState(false);

  const warehouseController = useRef(new AbortController());
  const databaseController = useRef(new AbortController());
  const schemaController = useRef(new AbortController());
  const permissionController = useRef(new AbortController());
  const { eventTrack } = useAnalyticsTracker();

  const { getTeamSettings } = useTeamSettings();
  const showKeyBasedAuth = !!getTeamSettings()?.sf_enable_key_pair_auth;

  const abortAPICall = (controller: React.MutableRefObject<AbortController>) => {
    controller.current.abort();

    // eslint-disable-next-line no-param-reassign
    controller.current = new AbortController();
  };

  const formikProps = useFormikContext<SnowflakeDestinationFormikProps>();

  const updateFormikValues = (data: any) => {
    let updatedState: any = {};
    formikProps.setValues(prevValues => {
      updatedState = {
        ...prevValues,
        ...data
      };
      return updatedState;
    });
    return updatedState;
  };

  useEffect(() => {
    onAccountUrlFieldBlur();
  }, []);

  const handleError = (err, defaultValues, trackingMetrics = {}) => {
    const fieldErrorCode = getBackendErrorCodeFromObj(err);
    const fieldErrorMessage = getErrorMessageFromObj(err);

    eventTrack({
      action: SnowflakeDestinationTrackingActions.ERROR_WHILE_FETCHING_RESOURCES,
      properties: {
        errorCode: fieldErrorCode,
        message: fieldErrorMessage,
        ...trackingMetrics
      }
    });

    let valuesToBeUpdated = {
      ...defaultValues
    };

    switch (fieldErrorCode) {
      case 1003:
        valuesToBeUpdated = {
          ...valuesToBeUpdated,
          invalidUserName:
            'The Username or Password is incorrect. Please try again with the correct credentials.',
          invalidPassword:
            'The Username or Password is incorrect. Please try again with the correct credentials.'
        };
        break;
      case 1005:
        valuesToBeUpdated = {
          ...valuesToBeUpdated,
          invalidAccountUrl: fieldErrorMessage
        };
        break;
      default:
        valuesToBeUpdated = {
          ...valuesToBeUpdated,
          formError: {
            message: fieldErrorMessage,
            errorCode: fieldErrorCode
          }
        };
        break;
    }
    updateFormikValues(valuesToBeUpdated);
  };

  const onAccountUrlFieldBlur = () => {
    let accountName = null;
    let regionId = null;
    let isPrivateLink = false;

    if (formikProps.values.accountUrl) {
      ({ accountName, regionId, isPrivateLink } = extractAccountAndRegionFromSnowflakeHostURL(
        formikProps.values.accountUrl
      ));
    }

    const updatedState = updateFormikValues({
      ...defaultUpdateState,
      accountName,
      region: regionId,
      isPrivateLink
    });

    if (isSnowflakeAutoFillExperimentVariant) {
      fetchWarehouses(updatedState);
      fetchDatabases(updatedState);
      fetchCreatePermission(updatedState);
    }
  };

  const onUserOrPasswordFieldBlur = () => {
    if (isSnowflakeAutoFillExperimentVariant && hasUserOrPasswordChanged()) {
      clearFieldsAndFetchData();
    }
  };

  const onDropdownFieldFocus = () => {
    if (isSnowflakeAutoFillExperimentVariant) {
      formikProps.validateForm().then(response => {
        formikProps.setTouched({
          user: true,
          password: true,
          accountUrl: true
        });
        const firstErrorField = Object.keys(response).find(field =>
          prerequisiteFields.find(mandatoryField => mandatoryField === field)
        );

        if (!firstErrorField) {
          return;
        }

        const selector = `[id="${firstErrorField}"]`;
        const errorElement: HTMLElement = document.querySelector(selector);

        if (errorElement) {
          errorElement.focus();
        }
      });
    }
  };

  const hasUserOrPasswordChanged = () =>
    formikProps.values.user !== prevCredentials.current.username ||
    formikProps.values.password !== prevCredentials.current.password;

  const clearFieldsAndFetchData = () => {
    prevCredentials.current = {
      username: formikProps.values.user,
      password: formikProps.values.password
    };

    formikProps.setTouched({
      user: true,
      password: true
    });

    updateFormikValues({
      ...defaultUpdateState,
      warehouse: null,
      database: null,
      schema: null,
      warehouseName: '',
      databaseName: '',
      schemaName: ''
    });

    fetchCreatePermission();
    fetchWarehouses();
    fetchDatabases();
  };

  const fetchCreatePermission = (formState = formikProps.values) => {
    if (!areRequiredFieldsValid(formState)) {
      return;
    }

    setUserSysAdminStatus(false);
    abortAPICall(permissionController);

    DestinationsAPI.getSnowflakeSysAdminPermission(formState, {
      signal: permissionController.current.signal
    })
      .then(({ data }) => {
        setUserSysAdminStatus(data);

        eventTrack({
          action: SnowflakeDestinationTrackingActions.USER_CREATE_PERMISSION_FETCHED,
          properties: {
            hasSysAdminPermission: data
          }
        });
      })
      .catch(err => {
        if (isPromiseCancelError(err)) {
          return;
        }

        eventTrack({
          action: SnowflakeDestinationTrackingActions.ERROR_WHILE_FETCHING_RESOURCES,
          properties: {
            errorCode: getBackendErrorCodeFromObj(err),
            message: getErrorMessageFromObj(err),
            resource: 'permission'
          }
        });

        setUserSysAdminStatus(false);
      });
  };

  const fetchWarehouses = (formikState = formikProps.values) => {
    if (!areRequiredFieldsValid(formikState)) {
      return;
    }

    setLoadingWarehouses(true);
    setShowNoWarehouseError(false);

    abortAPICall(warehouseController);

    DestinationsAPI.getSnowflakeWarehouses(formikState, {
      signal: warehouseController.current.signal
    })
      .then(({ data: res }) => {
        const warehousesList = res.map(option => ({
          ...addIdToDropdownOption(option?.warehouse || option),
          isDefaultWarehouse: option?.default_warehouse
        }));

        setWarehouses(warehousesList);
        setLoadingWarehouses(false);

        const currWarehouse = warehousesList.find(
          option => option.name === formikState.warehouseName
        );
        updateFormikValues({
          warehouse: currWarehouse,
          warehouseName: currWarehouse ? currWarehouse.name : ''
        });

        if (!warehousesList.length) {
          trackSetupGuideLink({
            ...configureWarehouseSetupGuide('warehouse-user-field-help-text'),
            medium: 'program',
            properties: destinationTypeTrackingProps
          });

          setShowNoWarehouseError(true);
        }
      })
      .catch(err => {
        if (isPromiseCancelError(err)) {
          return;
        }

        const valuesToBeUpdated: any = {
          warehouse: null,
          warehouseName: ''
        };

        handleError(err, valuesToBeUpdated, {
          resource: 'warehouse'
        });

        setWarehouses([]);
        setLoadingWarehouses(false);

        updateFormikValues(valuesToBeUpdated);
      });
  };

  const fetchDatabases = (formikState = formikProps.values) => {
    if (!areRequiredFieldsValid()) {
      return;
    }

    setLoadingDatabases(true);
    setShowNoDatabaseError(false);

    abortAPICall(databaseController);

    DestinationsAPI.getSnowflakeDatabases(formikState, {
      signal: databaseController.current.signal
    })
      .then(({ data: res }) => {
        const databasesList = res.map(option => addIdToDropdownOption(option));

        setDatabases(databasesList);
        setLoadingDatabases(false);
        const currDatabase = databasesList.find(option => option.name === formikState.databaseName);

        let valuesToBeUpdated: any = {
          database: currDatabase,
          databaseName: currDatabase ? currDatabase.name : ''
        };

        if (currDatabase) {
          fetchSchemas(currDatabase.name);
        } else {
          valuesToBeUpdated = {
            ...valuesToBeUpdated,
            schema: null,
            schemaName: ''
          };
        }

        updateFormikValues(valuesToBeUpdated);

        if (!databasesList.length) {
          trackSetupGuideLink({
            ...configureWarehouseSetupGuide('database-user-field-help-text'),
            medium: 'program',
            properties: destinationTypeTrackingProps
          });

          setShowNoDatabaseError(true);
          formikProps.setFieldValue('databaseError', '');
        }
      })
      .catch(err => {
        if (isPromiseCancelError(err)) {
          return;
        }

        const valuesToBeUpdated = {
          database: null,
          databaseName: '',
          schema: null,
          schemaName: ''
        };

        handleError(err, valuesToBeUpdated, {
          resource: 'database'
        });

        setDatabases([]);
        setLoadingDatabases(false);

        updateFormikValues(valuesToBeUpdated);
      });
  };

  const fetchSchemas = databaseName => {
    if (!areRequiredFieldsValid()) {
      return;
    }

    setLoadingSchemas(true);
    abortAPICall(schemaController);

    DestinationsAPI.getSnowflakeSchemas(formikProps.values, databaseName, {
      signal: schemaController.current.signal
    })
      .then(({ data: res }) => {
        const data = res.map(option => addIdToDropdownOption(option));

        setSchemas(data);
        setLoadingSchemas(false);

        const currSchema = data.find(option => option.name === formikProps.values.schemaName);
        updateFormikValues({
          schema: currSchema,
          schemaName: currSchema ? currSchema.name : ''
        });

        if (!data.length) {
          trackSetupGuideLink({
            ...configureWarehouseSetupGuide('schema-field-help-text'),
            medium: 'program',
            properties: destinationTypeTrackingProps
          });
        }
      })
      .catch(err => {
        if (isPromiseCancelError(err)) {
          return;
        }

        setSchemas([]);
        setLoadingSchemas(false);

        updateFormikValues({
          schema: null,
          schemaName: ''
        });
      });
  };

  const areRequiredFieldsValid = (formikState = formikProps.values) =>
    formikState.accountName && formikState.region && formikState.user && formikState.password;

  const addIdToDropdownOption = option => ({
    id: option,
    name: option
  });

  const { formError } = formikProps.values;

  const refreshWarehouses = () => {
    eventTrack({
      action: SnowflakeDestinationTrackingActions.WAREHOUSE_REFRESH_LIST_CLICK
    });

    fetchWarehouses();
  };

  const refreshDatabases = () => {
    eventTrack({
      action: SnowflakeDestinationTrackingActions.DATABASE_REFRESH_LIST_CLICK
    });

    fetchDatabases();
  };

  const refreshSchemas = () => {
    eventTrack({
      action: SnowflakeDestinationTrackingActions.SCHEMA_REFRESH_LIST_CLICK
    });

    fetchSchemas(formikProps.values.database.name);
  };

  const dataIdGenerator = getDataIdGenerator('destinationSnowflake');

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div
            className={showKeyBasedAuth ? `collapsible-group-box collapsible-group mb-5 pb-0` : ''}
          >
            {showKeyBasedAuth ? (
              <HdFormControl>
                <Field
                  name='authType'
                  component={ConnectionSettingsRadioGroup}
                  destinationTypeIdentifier={destinationTypeIdentifier}
                />
              </HdFormControl>
            ) : null}

            <Grid container>
              <Grid item xs={12}>
                <HdFormControl>
                  <Field
                    name='accountUrl'
                    label='Snowflake Account URL'
                    placeholder='https://account_name.region.snowflakecomputing.com'
                    helperText={
                      <Typography variant='caption'>
                        Enter the Snowflake Account URL (Ex:{' '}
                        <i>https://account_name.region.snowflakecomputing.com</i>
                        ).{' '}
                        <HdSetupGuideLink
                          {...hostUrlSetupGuide}
                          properties={destinationTypeTrackingProps}
                          size='sm'
                          icon='right'
                          dataId={dataIdGenerator('account-url')}
                        >
                          Learn More
                        </HdSetupGuideLink>
                      </Typography>
                    }
                    required
                    component={HdFormikTextField}
                    onFocus={() =>
                      trackSetupGuideLink({
                        ...hostUrlSetupGuide,
                        medium: 'program',
                        properties: destinationTypeTrackingProps
                      })
                    }
                    onBlur={onAccountUrlFieldBlur}
                  />
                </HdFormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <HdFormControl>
                  <Field
                    name='user'
                    label='Database User'
                    required
                    component={HdFormikTextField}
                    helperText={
                      <Typography variant='caption'>
                        Username of your Snowflake account.{' '}
                        <HdSetupGuideLink
                          {...configureWarehouseSetupGuide('database-user-field-help-text')}
                          properties={destinationTypeTrackingProps}
                          size='sm'
                          icon='right'
                          dataId={dataIdGenerator('database-user')}
                        >
                          Learn More
                        </HdSetupGuideLink>
                      </Typography>
                    }
                    onBlur={onUserOrPasswordFieldBlur}
                  />
                </HdFormControl>
              </Grid>

              {formikProps.values.authType === SnowflakeAuthenticationType.KEY_PAIR &&
              showKeyBasedAuth ? (
                <Grid item xs={12} className='pt-0'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <HdFormControl>
                        <Field
                          name='privateKey'
                          label='Private Key'
                          required
                          component={HdFormikFileField}
                          helperText={
                            <Typography variant='caption'>
                              Add the private key file.{' '}
                              <HdSetupGuideLink
                                {...configureWarehouseSetupGuide(
                                  'private-key-path-field-help-text'
                                )}
                                properties={destinationTypeTrackingProps}
                                size='sm'
                                icon='right'
                                dataId={dataIdGenerator('private-key-path')}
                              >
                                Learn More
                              </HdSetupGuideLink>
                            </Typography>
                          }
                        />
                      </HdFormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <HdFormControl>
                        <Field
                          name='passphrase'
                          label='Passphrase'
                          masked={maskSecretFields}
                          component={HdFormikPasswordField}
                          helperText={
                            <Typography variant='caption'>
                              Password used while generating the encrypted private key.{' '}
                              <HdSetupGuideLink
                                {...configureWarehouseSetupGuide('passphrase-field-help-text')}
                                properties={destinationTypeTrackingProps}
                                size='sm'
                                icon='right'
                                dataId={dataIdGenerator('passphrase')}
                              >
                                Learn More
                              </HdSetupGuideLink>
                            </Typography>
                          }
                        />
                      </HdFormControl>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <HdFormControl>
                    <Field
                      name='password'
                      label='Database Password'
                      required
                      masked={maskSecretFields}
                      component={HdFormikPasswordField}
                      helperText={
                        <Typography variant='caption'>
                          Password of your Snowflake account.{' '}
                          <HdSetupGuideLink
                            {...configureWarehouseSetupGuide('database-password-field-help-text')}
                            properties={destinationTypeTrackingProps}
                            size='sm'
                            icon='right'
                            dataId={dataIdGenerator('database-password')}
                          >
                            Learn More
                          </HdSetupGuideLink>
                        </Typography>
                      }
                      onBlur={onUserOrPasswordFieldBlur}
                    />
                  </HdFormControl>
                </Grid>
              )}
            </Grid>

            {!isSnowflakeAutoFillExperimentVariant && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <HdFormControl>
                      <Field
                        name='warehouseName'
                        label='Warehouse'
                        required
                        component={HdFormikTextField}
                        helperText={
                          <Typography variant='caption'>
                            Warehouse which you want to connect.{' '}
                            <HdSetupGuideLink
                              {...configureWarehouseSetupGuide('warehouse-field-help-text')}
                              properties={destinationTypeTrackingProps}
                              size='sm'
                              icon='right'
                              dataId={dataIdGenerator('warehouse')}
                            >
                              Learn More
                            </HdSetupGuideLink>
                          </Typography>
                        }
                      />
                    </HdFormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <HdFormControl>
                      <Field
                        name='databaseName'
                        label='Database Name'
                        required
                        component={HdFormikTextField}
                        helperText={
                          <Typography variant='caption'>
                            Database which you want to connect.{' '}
                            <HdSetupGuideLink
                              {...configureWarehouseSetupGuide('database-field-help-text')}
                              properties={destinationTypeTrackingProps}
                              size='sm'
                              icon='right'
                              dataId={dataIdGenerator('database')}
                            >
                              Learn More
                            </HdSetupGuideLink>
                          </Typography>
                        }
                      />
                    </HdFormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <HdFormControl>
                      <Field
                        name='schemaName'
                        label='Schema Name'
                        helperText='Schema name is case sensitive'
                        required
                        component={HdFormikTextField}
                      />
                    </HdFormControl>
                  </Grid>
                </Grid>
              </>
            )}

            {isSnowflakeAutoFillExperimentVariant && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <HdFormControl>
                      <Field
                        name='warehouse'
                        options={warehouses}
                        required
                        label='Warehouse'
                        helperText={
                          showNoWarehouseError ? (
                            <Typography variant='caption' className={styles.errorCaption}>
                              Your Snowflake account does not have a warehouse. Please create one
                              and try again.{' '}
                              <HdSetupGuideLink
                                {...configureWarehouseSetupGuide('warehouse-field-help-text')}
                                properties={destinationTypeTrackingProps}
                                size='sm'
                                icon='right'
                                dataId={dataIdGenerator('no-warehouse')}
                              >
                                Learn to create a warehouse.
                              </HdSetupGuideLink>
                            </Typography>
                          ) : (
                            <Typography variant='caption'>
                              Warehouse which you want to connect.{' '}
                              <HdSetupGuideLink
                                {...configureWarehouseSetupGuide('warehouse-field-help-text')}
                                properties={destinationTypeTrackingProps}
                                size='sm'
                                icon='right'
                                dataId={dataIdGenerator('warehouse')}
                              >
                                Learn More
                              </HdSetupGuideLink>
                            </Typography>
                          )
                        }
                        noOptionsText='No Warehouses found in your account.'
                        showLoading={loadingWarehouses}
                        component={HdFormikDropDown}
                        onFocus={onDropdownFieldFocus}
                        creatable={isUserSysAdmin}
                        creatableLabel='Warehouse'
                        TopAdornment={DropdownRefreshAdornment}
                        topAdornmentProps={{
                          handleRefreshResourceList: refreshWarehouses,
                          dataId: dataIdGenerator('warehouse')
                        }}
                        CustomOption={WarehouseDropdownOption}
                        creatableCallback={async (warehouse: string) =>
                          DestinationsAPI.createSnowflakeWarehouse(formikProps.values, warehouse)
                            .then(() => {
                              const option = addIdToDropdownOption(warehouse.toUpperCase());
                              setWarehouses([option, ...warehouses]);
                              updateFormikValues({
                                warehouse: option,
                                warehouseName: warehouse
                              });
                            })
                            .finally(() => {
                              eventTrack({
                                action: SnowflakeDestinationTrackingActions.CREATE_WAREHOUSE_CLICK,
                                properties: {}
                              });
                            })
                        }
                        onChangeEventHandler={selected =>
                          updateFormikValues({
                            warehouse: selected,
                            warehouseName: selected ? selected.name : ''
                          })
                        }
                      />
                    </HdFormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <HdFormControl>
                      <Field
                        name='database'
                        options={databases}
                        required
                        label='Database'
                        helperText={
                          showNoDatabaseError ? (
                            <Typography>
                              Your Snowflake account does not have a Database. Please create one and
                              try again.
                              <HdSetupGuideLink
                                {...configureWarehouseSetupGuide('database-field-help-text')}
                                properties={destinationTypeTrackingProps}
                                size='sm'
                                icon='right'
                                dataId={dataIdGenerator('no-database')}
                              >
                                Learn to create a Database.
                              </HdSetupGuideLink>
                            </Typography>
                          ) : (
                            <Typography variant='caption'>
                              Database which you want to connect.{' '}
                              <HdSetupGuideLink
                                {...configureWarehouseSetupGuide('database-field-help-text')}
                                properties={destinationTypeTrackingProps}
                                size='sm'
                                icon='right'
                                dataId={dataIdGenerator('database')}
                              >
                                Learn More
                              </HdSetupGuideLink>
                            </Typography>
                          )
                        }
                        showLoading={loadingDatabases}
                        component={HdFormikDropDown}
                        creatable={isUserSysAdmin}
                        onFocus={onDropdownFieldFocus}
                        TopAdornment={DropdownRefreshAdornment}
                        topAdornmentProps={{
                          handleRefreshResourceList: refreshDatabases,
                          dataId: dataIdGenerator('database')
                        }}
                        noOptionsText='No Databases found in your account.'
                        creatableLabel='Database'
                        creatableCallback={async database =>
                          DestinationsAPI.createSnowflakeDatabase(formikProps.values, database)
                            .then(() => {
                              const option = addIdToDropdownOption(database.toUpperCase());
                              setDatabases([option, ...databases]);
                              updateFormikValues({
                                database: option,
                                databaseName: database.toUpperCase()
                              });
                              fetchSchemas(database.toUpperCase());
                            })
                            .finally(() => {
                              eventTrack({
                                action: SnowflakeDestinationTrackingActions.CREATE_DATABASE_CLICK,
                                properties: {}
                              });
                            })
                        }
                        onChangeEventHandler={selected => {
                          updateFormikValues({
                            database: selected,
                            databaseName: selected ? selected.name : ''
                          });

                          if (selected) {
                            fetchSchemas(selected.name);
                          }
                        }}
                      />
                    </HdFormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <HdFormControl>
                      <Field
                        name='schema'
                        options={schemas}
                        required
                        label='Schema'
                        onFocus={onDropdownFieldFocus}
                        noOptionsText='No Schemas found in the Database.'
                        showLoading={loadingSchemas}
                        helperText='Schema which you want to connect.'
                        component={HdFormikDropDown}
                        onChangeEventHandler={selected =>
                          updateFormikValues({
                            schema: selected,
                            schemaName: selected ? selected.name : ''
                          })
                        }
                      />
                    </HdFormControl>
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        </Grid>
      </Grid>

      {!isDestinationModeWarehouse ? (
        <AdvancedConfig showBorderBottom>
          <LoadedAt />

          {/*  TODO:: THIS NEEDS TO BE REVERTED ONCE THE BACKEND FIXES SANITIZATION ISSUE */}
          {/* <SanitizeName
              disabled={!!isEditing}
            /> */}

          <HdFormikSwitchWrapper
            fieldName='isTableTypeTransient'
            disabled={!!isEditing}
            label='Create Transient Tables'
          >
            If enabled, Hevo will create transient tables instead of permanent tables in your
            Snowflake Destination.
            <HdDocLink
              docLink='/destinations/data-warehouses/snowflake/#4-specify-additional-settings'
              section='destination_settings'
              dataId={dataIdGenerator('transient-tables')}
            />
          </HdFormikSwitchWrapper>
        </AdvancedConfig>
      ) : null}

      {formError ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormApiError
              message={formError?.message}
              errorCode={formError?.errorCode}
              nodeType={destinationTypeIdentifier}
              nodeCategory={destinationMode.toString()}
              docLink={ConfigByType.getDocLink(destinationMode, destinationTypeIdentifier)}
              onClick={() => {}}
              dataId={dataIdGenerator('')}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
